/* @@@@@@@@@@@@@  Global CSS  @@@@@@@@@@@@@@ */

//Custom Container
@include breakpoint(xl) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1320px !important;
    }
}


/*======= Background Image ==========*/
.bg-img {
    background: url(../images/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}


/*============ Custom File Upload =============*/
.custom-upload {
    @extend %rel;
    @extend %d-inline-block;
    @extend %hidden;
    @extend %pointer;

    i {
        @extend %mr-5;
    }

    .file-btn {
        border-radius: 5px;
        @extend %pointer;

    }

    input[type="file"] {
        @extend %abs;
        @extend %pointer;
        left: 0;
        top: 0;
        opacity: 0;
    }
}


/*=========@@@@@@@@@ All Common Items   @@@@@@@@@============*/

//Member Item
.member-item {
    &.style-1 {
        .lab-inner {
            @extend %bg-primary;
            @include transition($transition);
            border-radius: 10px;
            overflow: hidden;
            .lab-thumb {
                @include transition($transition);
                overflow: hidden;

                img {
                    @extend %w-100;
                    filter: drop-shadow(0px 6px 5px rgba(148, 148, 148, 0.2));
                    @include transition($transition);
                }
            }

            .lab-content {
                @extend %p-20-10;
                @extend %text-center;

                * {
                    color: $white-color;
                }

                h6 {
                    @extend %text-clamp-1;
                    margin-bottom: 2px;
                    i {
                        margin-left: 5px;
                        background: #1da1f2;
                        border-radius: 100%;
                        width: 14px;
                        height: 14px;
                        line-height: 14px;
                        text-align: center;
                        font-size: 12px;
                        color: $white-color;
                        display: inline-block;
                    }
                }

                p {
                    @extend %mb-0;
                    font-size: $fs-small;
                }
            }
        }

        &:hover {
            .lab-inner {
                @extend %bg-theme;

                .lab-thumb {
                    img {
                        transform: scale(1.2);
                    }
                }
                .lab-content {
                    h6 {
                        i {
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }

    &.style-2 {
        .lab-inner {
            .lab-thumb {
                @extend %p-10;
                @extend %pb-0;
                @extend %mb-0;

                @include breakpoint(sm) {
                    padding: 10px;
                    margin-bottom: 0;
                }

                img {
                    transform: none;
                    padding: 0 !important;
                }
            }
        }
    }
}

//About Item
.about-item {
    .lab-inner {
        @extend %bg-primary;
        padding: 50px;
        @include border-radius(4px);
        .lab-content {
            @extend %rel;
            padding-top: 25px;

            * {
                color: $white-color;
            }

            h2 {
                @extend %mb-0;
                line-height: 1.2;
            }

            p {
                @extend %mb-0;
            }
        }
    }
}


//work item
.work-item {

    &:hover {
        .lab-inner .lab-thumb .thumb-inner {
            .step {
                background: $theme-color;
                border-color: $theme-color;
            }

            span,
            p {
                color: $white-color;
            }
        }

        .lab-inner .lab-content h4 {
            color: $theme-color;
        }
    }

    .lab-inner {
        .lab-thumb {
            @extend %mb-15;

            @include breakpoint(sm) {
                margin-bottom: 20px;
            }

            .thumb-inner {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                @extend %rel;
                margin: 0 auto;

                .step {
                    @extend %abs;
                    @extend %bg-white;
                    @include transition($transition);

                    * {
                        color: $primary-color;
                        font-family: $font;
                    }

                    top: -20px;
                    right: 5px;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 3px solid $primary-color;
                    filter: drop-shadow(0px 10px 5px rgba(255, 255, 255, 0.2));

                    span {
                        font-size: 14px;
                        @extend %text-bold;
                        text-transform: capitalize;
                        font-family: $font;
                        @include transition($transition);
                    }

                    p {
                        @extend %m-0;
                        line-height: 1;
                        margin-top: -2px;
                        @include transition($transition);
                        font-size: 14px;
                        font-family: $font;

                    }
                }
            }


        }

        .lab-content {
            h4 {
                @extend %mb-10;

                @include transition($transition);

                @include breakpoint(sm) {
                    margin-bottom: 20px;
                }
            }

            p {
                @extend %mb-0;
            }
        }
    }
}

//story item
.story-item {
    @extend %bg-primary;
    border-radius: 10px;
    overflow: hidden;

    .lab-inner {
        &:hover {
            .lab-content {
                background: $theme-color;
            }
        }
        .lab-thumb {
            @extend %mb-0;
            @extend %hidden;
            img {
                @include transition($transition);
                @extend %w-100;
            }
        }

        .lab-content {
            padding: 20px 30px 30px;
            @include transition($transition);
            h4 {
                margin-bottom: 15px;
                @extend %text-clamp-2;
            }
            .lab-content-author {
                @extend %flex;
                align-items: center;
                .left {
                    width: 50px;
                    img {
                        @include border-radius(100%);
                    }
                }
                .right {
                    width: calc(100% - 50px);
                    padding-left: 15px;
                    a {
                        font-weight: 700;
                        @extend %mb-5;
                        display: inline-block;
                    }
                    span {
                        font-size: 14px;
                        display: block;
                    }
                }
            }
        }
    }

    &:hover {
        .lab-inner {
            .lab-thumb {
                img {
                    transform: scale(1.15) rotate(3deg);
                }
            }

            .lab-content {
                h4 {
                    a {
                        color: $theme-color;
                    }
                }
            }
        }
    }
}


// meet section
.meet {
    &__item {}
    &__inner {
        @extend %rel;
        &:hover {
            .meet {
                &__thumb {
                    img {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
    &__thumb {
        overflow: hidden;
        border-radius: 10px;
        a {
            @extend %d-block;
        }
        img {
            @extend %w-100;
        }
    }
    &__content {
        @extend %abs;
        bottom: 30px;
        left: 30px;
        z-index: 1;
        a {
            @extend %d-block;
        }
        h4 {
            @extend %white-color;
            @extend %mb-0;
        }
        img {
            border-radius: 100%;
            @extend %mb-15;
        }
    }
    
}

//Group Item
.group-item {
    @include breakpoint(md) {
        height: 100%;
    }

    .lab-inner {
        @extend %bg-primary;
        @include transition($transition);
        @include border-radius(10px);
        overflow: hidden;
        @include breakpoint(md) {
            height: 100%;
        }

        .lab-thumb {
            overflow: hidden;
            img {
                @extend %w-100;
                object-fit: cover;
            }
        }

        .lab-content {
            padding: 20px;

            h4 {
                @extend %mb-10;
                @extend %text-1;
            }
            p {
                @extend %text-2;
            }
            .lab-content-icon {
                @extend %flex;
                justify-content: center;
                li {
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    color: $title-color;
                    padding: 0;
                    margin: 5px;
                    background: rgba($white-color, $alpha: .20);
                    @include border-radius(100%);
                    @extend %rel;
                    @extend %pointer;
                    &:hover {
                        .time-tooltip {
                            opacity: 1;
                            visibility: visible;
                            -webkit-transform: translate(-50%, -10px);
                            -moz-transform: translate(-50%, -10px);
                            -ms-transform: translate(-50%, -10px);
                            transform: translate(-50%, -10px);
                        }
                    }
                    .time-tooltip {
                        @extend %abs;
                        bottom: 0;
                        left: 40%;
                        width: max-content;
                        height: auto;
                        border-radius: 3px;
                        background-color: $primary-color;
                        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                        opacity: 0;
                        visibility: hidden;
                        -webkit-transform: translate(-50%, -20px);
                        -moz-transform: translate(-50%, -20px);
                        -ms-transform: translate(-50%, -20px);
                        transform: translate(-50%, -20px);
                        text-align: center;
                        padding: 2px 15px;
                        z-index: 4;
                        -moz-transition: all 0.3s ease-in-out;
                        -webkit-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                        margin-bottom: 30px;
                        &::after {
                            content: "";
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            background: $primary-color;
                            -webkit-transform: rotate(45deg) translateX(-50%);
                            -moz-transform: rotate(45deg) translateX(-50%);
                            -ms-transform: rotate(45deg) translateX(-50%);
                            transform: rotate(45deg) translateX(-50%);
                            bottom: -7px;
                            left: 50%;
                            -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                            z-index: -1;
                        }
                        &::before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            content: "";
                            background: $primary-color;
                            border-radius: 10px;
                        }
                        .time-tooltip-holder {
                            @extend %rel;
                            z-index: 1;
                            .tooltip-label {
                                display: block;
                                width: 100%;
                                font-size: 14px;
                                line-height: 20px;
                                color: $white-color;
                            }
                            .tooltip-info {
                                display: block;
                                font-size: 12px;
                                line-height: 20px;
                                color: $white-color;
                            }
                        }
                    }
                }
            }

            .img-stack {
                @extend %mb-20;

                li {
                    width: 40px;
                    height: 40px;
                    display: grid;
                    place-items: center;
                    border-radius: 50%;
                    box-shadow: 0px 0px 0px 2px rgba($white-color, $alpha: .30);

                    img {
                        @extend %w-100;
                        @extend %h-100;
                    }

                    &:nth-child(7n+2) {
                        transform: translateX(-10px);
                    }

                    &:nth-child(7n+3) {
                        transform: translateX(-20px);
                    }

                    &:nth-child(7n+4) {
                        transform: translateX(-30px);
                    }

                    &:nth-child(7n+5) {
                        transform: translateX(-40px);
                    }

                    &:nth-child(7n+6) {
                        transform: translateX(-50px);
                    }

                    &:nth-child(7n+7) {
                        transform: translateX(-60px);
                    }
                }
            }

            .lab-btn {
                @extend %bg-white;
                color: $primary-color;
                line-height: 40px;
                padding: 0 1.5rem;
                @extend %border-radius-2;

                i {
                    color: $primary-color;
                }

                &:hover {
                    background-color: $primary-color;
                    color: $white-color;

                    i {
                        color: $white-color;
                    }
                }
            }
        }
    }

    &:hover {
        .lab-inner {
            @extend %bg-theme;
            .lab-thumb {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }

    &.style-1 {
        .lab-inner {
            @extend %bg-primary;
            flex-direction: column;

            @include breakpoint(md) {
                flex-direction: row;
            }

            .lab-thumb {
                @extend %w-100;

                @include breakpoint(md) {
                    width: 40%;

                    img {
                        width: 100%;
                    }
                }
            }

            .lab-content {
                @extend %w-100;

                @include breakpoint(md) {
                    width: calc(100% - 40% - 24px);
                }

                .lab-btn {
                    &:hover {
                        @extend %bg-theme;
                    }
                }
            }
        }
    }
}

//pricing-item
.price-item {
    @include transition($transition);

    .price-item-inner {
        @extend %text-center;
        @extend %bg-primary;
        border-radius: 10px;
        @extend %hidden;
        @include transition($transition);

        .price-top {
            padding-top: 40px;
            padding-bottom: 100px;
            background: url(../images/pricing-plan/pricing-plan.svg);
            background-repeat: no-repeat;
            background-size: cover;

            h2 {
                @extend %mb-5;
            }

            p {
                max-width: 238px;
                margin: 0 auto;
            }
        }

        .price-bottom {
            padding-bottom: 40px;

            ul {

                li {
                    padding: 7px 10px;
                    @extend %flex;
                    @extend %align-items-center;
                    @extend %justify-center;

                    i {
                        @extend %mr-5;
                    }

                    p {
                        @extend %mb-0;
                    }
                }
            }

            .purchase-btn {
                margin-top: 30px;
                @extend %bg-theme;
                @extend %d-inline-block;
                line-height: 56px;
                padding: 0 3rem;
                @extend %border-radius-2;
                @include transition($transition);

                &:hover {
                    box-shadow: 0px 10px 10px 0px rgba(224, 49, 77, 0.2);
                    transform: translateY(-3px);
                }
            }
        }
    }

    &:hover,
    &.active {
        .price-item-inner {
            background: $white-color;

            .price-bottom {
                color: $primary-color;
            }
        }

        @include breakpoint(lg) {
            transform: translateY(-30px);
        }
    }
}

//member-filter tab
.member-filter {
    .member-filter-inner {
        @extend %p-20;
        @extend %bg-theme;

        .filter-form {
            @extend %flex;
            justify-content: space-evenly;

            >div {
                @extend %mb-10;

                @include breakpoint(sm) {
                    &:nth-child(4) {
                        margin-bottom: 0;
                    }
                }

                @include breakpoint(xl) {
                    margin-bottom: 0;
                }
            }

            .custom-select {
                select {
                    background: $body-color !important;
                    padding: 7px 10px;
                    padding-right: 120px;
                    border: 1px solid rgba($white-color, $alpha: .10);
                    outline: none;
                    box-shadow: none;

                }
            }

            .age {
                .custom-select {
                    &:first-child {
                        @extend %mr-10;

                        @include breakpoint(lg) {
                            @include breakpoint(max-xl) {
                                margin-right: 3px;
                            }
                        }
                    }

                    select {
                        padding-right: 50px;
                    }
                }
            }

            .lab-btn {
                outline: none;
                border: none;
                box-shadow: none;
                background: $body-color;
                color: $white-color;
                padding: 0 1rem;
                @extend %ml-15;
                line-height: 40px;

                i {
                    @extend %ml-5;
                }

                &:hover {
                    box-shadow: 0 3px 5px rgba($white-color, $alpha: .30);
                    transform: translateY(-2px);
                }
            }
        }
    }
}

//Actice group search
.group-search {
    @extend %flex;
    @extend %justify-between;
    @extend %mb-30;

    .group-count {
        @extend %flex;

        @include breakpoint(max-sm) {
            margin-bottom: 10px;
        }

        p {
            @extend %mb-0;
            padding: 16px 30px;
            font-family: $font;
            @extend %border-radius-2;

            &:first-child {
                @extend %bg-primary;
            }

            &:last-child {
                @extend %bg-theme;
                @extend %rel;

                &:before {
                    @extend %abs;
                    left: -7px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 0;
                    height: 0;
                    border-width: 10px 10px 10px 0;
                    border-color: transparent $theme-color transparent transparent;
                    border-style: solid;
                }
            }
        }
    }

    .group-search-btn {
        form {
            @extend %flex;

            input {
                outline: none;
                border: none;
                box-shadow: none;
                line-height: 40px;
                padding: 8px 20px;
            }

            input[type="text"] {
                @extend %bg-primary;
                width: 190px;

                @include breakpoint(md) {
                    width: auto;
                }

                &::placeholder {
                    color: $white-color;
                    opacity: .9;
                }
            }

            .input-button {
                @extend %rel;

                input[type="submit"] {
                    @extend %bg-theme;
                    @extend %pl-10;

                    @include breakpoint(md) {
                        padding-left: 30px;
                    }
                }

                &:before {
                    @extend %abs;
                    left: -7px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 0;
                    height: 0;
                    border-width: 10px 10px 10px 0;
                    border-color: transparent $theme-color transparent transparent;
                    border-style: solid;
                }
            }

        }
    }
}


// preloader
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    background: $theme-color;
    @extend %hidden;

    .preloader-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .preloader-icon {
            width: 72px;
            height: 72px;
            display: inline-block;
            padding: 0px;

            span {
                position: absolute;
                display: inline-block;
                width: 72px;
                height: 72px;
                border-radius: 100%;
                background: #fff;
                -webkit-animation: preloader-fx 1.6s linear infinite;
                animation: preloader-fx 1.6s linear infinite;

                &:last-child {
                    animation-delay: -0.8s;
                    -webkit-animation-delay: -0.8s;
                }
            }
        }
    }
}


@keyframes preloader-fx {
    0% {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 0;
    }
}

@-webkit-keyframes preloader-fx {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes price-ani {
    0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2);
        opacity: 0.1;
    }
}








.bg-color {
    @extend %bg-color;
}

.theme-color {
    color: $theme-color !important;
}

// scrollTop
.scrollToTop {
    position: fixed;
    bottom: -30%;
    right: 5%;
    z-index: 9;

    i {
        @extend %d-inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        @extend %text-center;
        color: $white-color;
        @extend %bg-theme;
        border: 1px solid rgba($white-color, $alpha: .10);
        font-size: $fs-h4;
        @include transition($transition);

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0px 5px 20px 0px rgba(0, 113, 240, 0.49)
        }
    }
}


@include breakpoint(lg) {
    .pt-lg-110 {
        padding-top: 108px !important;
    }

    .pb-lg-110 {
        padding-bottom: 110px !important;
    }
}

.breadcrumb {
    background-color: transparent;
}

.RevActive .rev, .DescActive .desc {
    background-color: $theme-color !important;
}
.gridActive .grid, .listActive .list {
    color: $theme-color !important;
}