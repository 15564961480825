.swiper-button-prev,
.swiper-button-next {
    width: 25px;
    height: 25px;
    background-color: rgba(13, 54, 135, 0.5);
    color: white;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    z-index: 10;
    --swiper-navigation-size: 10px;
}

.swiper-button-prev {
    left: 5px;
    font-weight: bold
}

.swiper-button-next {
    right: 5px;
    font-weight: bold;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    background-color: rgba(242, 69, 112, 0.5);
}