.app {
    &__item {
        
    }
    &__thumb {
        @extend %text-center;
    }
    &__content {
        margin-top: 25px;
        h4 {
            @extend %text-regular;
            @extend %theme-color;
        }
        h2 {
            @extend %mb-15;
        }
        p {
            @extend %mb-30;
            line-height: 30px;
            @include font-size(18px);
        }
        ul {
            @extend %flex;
            li {
                @extend %m-10;
                &:nth-child(1) {
                    margin-left: 0;
                }
                &:nth-child(2) {
                    @include breakpoint(max-sm) {
                        margin-left: 0;
                    }
                }
                a {
                    box-shadow: 0px 0px 20px rgba($shadow-color, $alpha: .20);
                    @extend %of-hidden;
                    @include add-prefix(border-radius, (4px));
                    &:hover {
                        box-shadow: none;
                    }
                }
            }
        }
    }
    &--style2 {
        @extend %p-rel;
        &::after {
            @extend %abs;
            background: url(../../assets/images/app/bg.png) no-repeat;
            background-position: center;
            background-size: 100% 100%;
            width: 70%;
            height: 100%;
            left: 50%;
            top: 0;
            @include add-prefix(transform, (translateX(-50%)));
        }
        * {
            @extend %p-rel;
            @extend %z-index-1;
        }
        .app {
            &__content {
                ul {
                    justify-content: center;
                    align-items: center;
                    li {
                        img {
                            border-radius: 4px;
                        }
                        &.counter-after {
                            width: 50px;
                            height: 80px;
                            margin: 0 5px;
                            margin-top: 4px;
                            background: $primary-color;
                            @include border-radius(3px);
                        }
                        h3 {
                            font-size: 50px;
                            color: $white-color;
                            font-weight: 400;
                            text-transform: uppercase;
                            cursor: auto;
                            .odometer-digit {
                                width: 60px;
                                margin: 0 5px;
                                background: $primary-color;
                                @include border-radius(3px);
                                text-align: center;
                                @extend %p-rel;
                                &::before {
                                    @extend %p-abs;
                                    width: 100%;
                                    height: 50%;
                                    left: 0;
                                    top: 0;
                                    background: rgba($white-color, $alpha: .1);
                                }
                                .odometer-digit-inner {
                                    height: 100%;
                                    .odometer-ribbon {
                                        height: 100%;
                                        .odometer-ribbon-inner {
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                            .odometer-formatting-mark {
                                transform: translateY(20px);
                                display: inline-block;
                            }
                        }
                    }
                }

            }
        }
    }
}