.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
}

.dot {
    width: 15px;
    height: 15px;
    margin: 5px;
    border-radius: 50%;
    background-color: #3498db;
    animation: dotMove 1.5s ease-in-out infinite;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.1s;
}

.dot:nth-child(3) {
    animation-delay: 0.2s;
}

.dot:nth-child(4) {
    animation-delay: 0.3s;
}

.dot:nth-child(5) {
    animation-delay: 0.4s;
}

.dot:nth-child(6) {
    animation-delay: 0.5s;
}

@keyframes dotMove {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
        background-color: #e74c3c;
    }
}

.loading-text {
    margin-top: 20px;
    font-size: 1.4rem;
    color: #ecf0f1;
    letter-spacing: 2px;
    animation: fadeIn 2s infinite;
}

@keyframes fadeIn {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}